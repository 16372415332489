import React, { lazy, Suspense } from "react";

import { Box } from "@mui/material";
import { DndProvider } from "react-dnd";
import { client } from "./config/apollo";

import { ApolloProvider } from "@apollo/client";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/loading";

const Error = lazy(() => import("./pages/404"));

const ContentManage = lazy(() => import("./pages/contentManage"));
const ContentCategory = lazy(() => import("./pages/contentCategoryManage"));
const AirportFacility = lazy(() => import("./pages/airportFacility"));
const PrivilegeLayout = lazy(() => import("./pages/privilegeLayout"));
const FlightShare = lazy(() => import("./pages/flightShare"));

const FormContent = lazy(() => import("./pages/form/formContent"));
const FormContentCategory = lazy(() =>
  import("./pages/form/formContentCategory")
);
const FormAirportFacility = lazy(() =>
  import("./pages/form/formAirportFacility")
);

const ContentShortcut = lazy(() =>
  import("./pages/mainSection/contentShortcut")
);

const FormContentShortcut = lazy(() =>
  import("./pages/form/formContentShortcut")
);

const ContentRecommend = lazy(() =>
  import("./pages/mainSection/contentRecommend")
);
const FormContentRecommend = lazy(() =>
  import("./pages/form/formContentRecommend")
);

const ContentHighlight = lazy(() =>
  import("./pages/mainSection/contentHighlight")
);
const FormContentHighlight = lazy(() =>
  import("./pages/form/formContentHighlight")
);
const FormFlightShare = lazy(() => import("./pages/form/formFlightShare"));

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <Box p={3}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="*" element={<Error />} />
               
                {/* CONTENT MANAGE */}
                <Route
                  path="/content-manage/content"
                  element={<ContentManage />}
                />
                <Route
                  path="/content-manage/content/form"
                  element={<FormContent />}
                />

                {/* Content category */}
                <Route
                  path="/content-manage/category"
                  element={<ContentCategory />}
                />
                <Route
                  path="/content-manage/category/form"
                  element={<FormContentCategory />}
                />

                {/* CONTENT FLIGHT SHARE */}
                <Route
                  path="/content-manage/flight-share"
                  element={<FlightShare />}
                />


                <Route
                  path="/content-manage/flight-share/form"
                  element={<FormFlightShare />}
                />

                {/* CONTENT FLIGHT SHARE */}
                <Route
                  path="/content-manage/flight-share"
                  element={<FlightShare />}
                />


                {/* HOME MANAGE */}
                <Route
                  path="/home-manage/airport-facility"
                  element={<AirportFacility />}
                />
                <Route
                  path="/home-manage/airport-facility/form"
                  element={<FormAirportFacility />}
                />
                <Route
                  path="/home-manage/content-shortcut"
                  element={<ContentShortcut />}
                />

                <Route
                  path="/home-manage/content-shortcut/form"
                  element={<FormContentShortcut />}
                />

                <Route
                  path="/home-manage/content-recommend"
                  element={<ContentRecommend />}
                />

                <Route
                  path="/home-manage/content-recommend/form"
                  element={<FormContentRecommend />}
                />

                <Route
                  path="/home-manage/content-highlight"
                  element={<ContentHighlight />}
                />
                <Route
                  path="/home-manage/content-highlight/form"
                  element={<FormContentHighlight />}
                />
                <Route
                  path="/home-manage/privilege"
                  element={<PrivilegeLayout />}
                />

                
              </Routes>
            </Suspense>
          </BrowserRouter>
        </DndProvider>
      </Box>
    </ApolloProvider>
  );
}
